import $ from 'jquery';

$.fn.reverseMobileSlider = function() {

    var jqAllAffectedContainers = this;

    return jqAllAffectedContainers.each(function() {
        var jqContainer = $(this);
        var sliderContainer = jqContainer.find('.reverse-slider-container');
        if (!sliderContainer || sliderContainer === undefined) {
            console.error('reverse-slider: no reverse-slider-container fround in', this);
        }
        var prev = jqContainer.find('.prev');
        var next = jqContainer.find('.next');
        var nav = jqContainer.find('.nav');
        var show = jqContainer.data('show') ? jqContainer.data('show') : 4;
        var showMD = jqContainer.data('showMd') ? jqContainer.data('showMd') : 3;
        var showXS = jqContainer.data('showXs') ? jqContainer.data('showXs') : 1;
        var scroll = jqContainer.data('scroll') ? jqContainer.data('scroll') : 1;
        var scrollMD = jqContainer.data('scrollMd') ? jqContainer.data('scroll') : 1;
        var scrollXS = jqContainer.data('scrollXs') ? jqContainer.data('scroll') : 1;
        var arrows = jqContainer.data('arrows') ? jqContainer.data('arrows') : false;
        var arrowsMD = jqContainer.data('arrowsMd') ? jqContainer.data('arrowsMd') : false;
        var arrowsXS = jqContainer.data('arrowsXs') ? jqContainer.data('arrowsXs') : false;
        var dots = jqContainer.data('dots') ? jqContainer.data('dots') : false;
        var dotsMD = jqContainer.data('dotsMd') ? jqContainer.data('dotsMd') : false;
        var dotsXS = jqContainer.data('dotsXs') ? jqContainer.data('dotsXs') : false;
        var infinite = jqContainer.data('infinite') ? jqContainer.data('infinite') : false;
        var autoplay = jqContainer.data('autoplay') ? jqContainer.data('autoplay') : false;
        var autoplaySpeed = jqContainer.data('autoplaySpeed') ? jqContainer.data('autoplaySpeed') : 2000;
        var variableWidth = jqContainer.data('variableWidth') ? jqContainer.data('variableWidth') : false;
        var disableMD = jqContainer.data('disableMd') ? jqContainer.data('disableMd') : false;
        var initialSlide = jqContainer.data('initialSlide') ? jqContainer.data('initialSlide') : 0;
        var mdSettings = {
            breakpoint: 1278,
            settings: {
                slidesToShow: showMD,
                arrows: arrowsMD,
                dots: dotsMD,
                slidesToScroll: scrollMD,
            }
        }
        if (disableMD) {
            mdSettings = {
                breakpoint: 725,
                settings: "unslick"
            }
        }
        var options = {
            mobileFirst: true,
            slidesToShow: show,
            arrows: arrows,
            dots: dots,
            slidesToScroll: scroll,
            infinite: infinite,
            prevArrow: prev,
            nextArrow: next,
            appendDots: nav,
            autoplay: autoplay,
            autoplaySpeed: autoplaySpeed,
            variableWidth: variableWidth,
            focusOnSelect: true,
            initialSlide: initialSlide,
            responsive: [
                {
                    breakpoint: 1279,
                    settings: "unslick"
                },
                {
                    ...mdSettings,
                },
                {
                    breakpoint: 724,
                    settings: {
                        slidesToShow: showXS,
                        arrows: arrowsXS,
                        dots: dotsXS,
                        slidesToScroll: scrollXS
                    }
                },
            ]
        }
        sliderContainer.slick(options);
    });

};

document.addEventListener('DOMContentLoaded', function() {

    $('.reverse-mobile-slider').reverseMobileSlider();

});
