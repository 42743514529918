import $ from 'jquery';

/**
 * Back to top script
 * @returns {jQuery} 
 */
$.fn.customBackToTop = function() {
    /**
     * @type {jQuery} - all containers affected by selector
     */
    var jqAllAffectedContainers = this;
   
    jqAllAffectedContainers.each(function() {
       const container = $(this);
       container.on('click', function(e) {
            e.preventDefault();
            $("html, body").animate(
                {scrollTop: 0},
                600,
                "linear"
            );
       });
       $(window).on('scroll load', function(){
            const top = window.scrollY;
            const screenSize = window.innerHeight;
            if (top > screenSize) {
                container.addClass('shown');
            } else {
                container.removeClass('shown');
            }
      });
    });

    return jqAllAffectedContainers;
};

document.addEventListener('DOMContentLoaded', function() {
    $('.back-to-top-js').customBackToTop();
});
