import $ from 'jquery';
import Structure from '../modules/structure';

/**
 * Back to top script
 * @returns {jQuery} 
 */
$.fn.localMobileMenu = function() {
    /**
     * @type {jQuery} - all containers affected by selector
     */
    var jqAllAffectedContainers = this;
    const bodyContainer = $('body');
   
    jqAllAffectedContainers.each(function() {
       const container = $(this);
       const structure = new Structure('ratingField', container, {
            btn: '.open-local-mobile-menu',
        }).structure;
        structure.btn.on('click', function(e) {
            e.preventDefault();
            if (container.hasClass('open')) {
                container.removeClass('open');
                bodyContainer.removeClass('non-scrollable');
            } else {
                container.addClass('open');
                bodyContainer.addClass('non-scrollable');
            }
        });
    });

    return jqAllAffectedContainers;
};

document.addEventListener('DOMContentLoaded', function() {
    $('.local-mobile-menu-js').localMobileMenu();
});
