import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import $ from 'jquery';

function customFancyboxInit(fancyboxLinkNode) {
	if (fancyboxLinkNode === undefined || !fancyboxLinkNode) {
        console.error('customFancyboxInit: invalid fancyboxLinkNode ', fancyboxLinkNode);
        return false;
    }
	let fancyboxContentId = fancyboxLinkNode.getAttribute("href");
	if (!fancyboxContentId || fancyboxContentId.length === 0) {
		console.error('customFancyboxInit: invalid fancyboxContentId from href attribute ', fancyboxLinkNode);
		return false;
	}
	var ps = null;

	fancyboxLinkNode.addEventListener('click', function(e) {
		e.preventDefault();
		Fancybox.show([{
			src  : fancyboxContentId,
			type : 'inline',
			mainClass: "custom-fancybox",
			closeButton: false,
			on: {
				done : function( instance, current ) {
					if ($('.custom-scroll.scroll-fancybox').hasClass('ps')) {
						if (ps !== null) ps.update();
					} else {
						ps = $('.custom-scroll.scroll-fancybox').customScroll();
						ps.update();
					}
				},
				reveal: function( instance, current ) {
					$('.common-item-slider-holder').slick('refresh');
				}
			}
		}]);
	});
}

$("body").on("click", ".closePopUp", function(){
	Fancybox.close();
	return false;
});

document.addEventListener('DOMContentLoaded', function() {
    const fancyboxLinkNodes = document.getElementsByClassName('custom-fancybox');
    for(let i = 0; i < fancyboxLinkNodes.length; i++) {
        let fancyboxLinkNode = fancyboxLinkNodes.item(i);
        const customFancyboxObj = new customFancyboxInit(fancyboxLinkNode);
    }

	Fancybox.bind("[data-fancybox]", {
		// Your custom options
	});
});
