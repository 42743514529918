import baseStyles from '../sass/base.scss';
import $ from 'jquery';
import 'slick-carousel';
import 'selectric';
import "selectric/public/selectric.css";
import anime from 'animejs/lib/anime.es.js';
import mobileMenuJS from './includes/mobile_menu.js';
import customFancyboxJS from './includes/custom_fancybox.js';
import customScrollsJS from './includes/custom_scrolls.js';
import commonSliderJS from './includes/common_slider.js';
import customTabsJS from './includes/custom_tabs.js';
import ratingFieldJS from './includes/rating_field.js';
import collapsabaleXSJS from './includes/collapsable_xs.js';
import localMobileMenuJS from './includes/local_mobile_menu.js';
import backToTopJS from './includes/back_to_top.js';
import reverseMobileSliderJS from './includes/reverse_mobile_slider.js';

$(document).ready(function() {
    $('.selectric-select').selectric({
        maxHeight: 164,
        disableOnMobile: false,
        nativeOnMobile: true
    });
});

anime({
    targets: 'div.anime-hello-world',
    translateX: 250,
    rotate: '1turn',
    backgroundColor: 'blue',
    duration: 800,
    direction: 'alternate',
    loop: true,
    easing: 'easeInOutSine'
});

const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()