import $ from 'jquery';
import Structure from '../modules/structure';

/**
 * Back to top script
 * @returns {jQuery} 
 */
$.fn.callapsabaleXS = function() {
    /**
     * @type {jQuery} - all containers affected by selector
     */
    var jqAllAffectedContainers = this;
   
    jqAllAffectedContainers.each(function() {
        const container = $(this);
        const structure = new Structure('ratingField', container, {
            open: '.collapse-action .open',
            close: '.collapse-action .close'
        }).structure;
        structure.open.on('click', function(e) {
            e.preventDefault();
            container.addClass('open');
        });
        structure.close.on('click', function(e) {
            e.preventDefault();
            container.removeClass('open');
        });
    });

    return jqAllAffectedContainers;
};

document.addEventListener('DOMContentLoaded', function() {
    $('.collapsabale-xs-js').callapsabaleXS();
});
