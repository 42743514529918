"use strict";
import $ from 'jquery';

/**
 * Custom Structure Error for Structure realted errors
 */
export class StructureError extends Error {
    constructor(message) {
        super(message);
        this.name = "StructureError";
      }
}

/**
 * Structure class to ensure certian DOM structure  
 */
export default class Structure {
    #_name = "";
    #_template = {};
    #_globalContainer;
    
    /**
     * Contructor
     * Costruct Strucute object and check parameters
     * @param {string} name - name for structure error messages
     * @param {jQuery} globalContainer - jQuery container to test tempalte against
     * @param {Obejct.<string, string>} template - object containing string name keys and jQuery selectors as values
     */
    constructor(name, globalContainer, template) {
        this.#_name = name;
        this.#_globalContainer = globalContainer;
        this.#_template = template;
        if (!(this.#_globalContainer instanceof $)) {
            throw new StructureError(`${this.#_name}: global container is not jQuery object. globalContainer provided is: ${this.#_globalContainer}`);
        }
        if (Object.keys(this.#_template).length === 0 && this.#_template.constructor === Object) {
            throw new StructureError(`${this.#_name}: empty template. Tempalte provided ${this.#_template}`);
        }
    }

    /**
     * Private
     * Prints into log console structure required by template
     */
    #logRequiredStructure() {
        var requirements = [
            `${this.#_name} Requires structue tempalte:`,
        ];
        for (const [key, value] of Object.entries(this.#_template)) {
            requirements.push(value);
        }
        for (var line of requirements) console.log(line);
    }
    
    /**
     * Getter
     * Build structure by provided template
     */
    get structure() {
        const finalStructure = {};
        for (const [key, value] of Object.entries(this.#_template)) {
            let selector = value;
            let many = false;
            if (value.constructor === Object) {
                selector = value.selector;
                many = value.many;
            }
            finalStructure[key] = this.#findAndCheckContainer(selector, many);
        }
        finalStructure['_global'] = this.#_globalContainer;
        return finalStructure;
    }

    /**
     * Private
     * Get element by selector and if not found print tempalte strucure
     * @param {string} findContainerBySelector - jQuery compatable selector
     * @param {bool} [many=false] - return only first found 
     * @returns {jQuery} - container found by selector
     */
    #findAndCheckContainer(findContainerBySelector, many=false) {
        var containerFound = this.#_globalContainer.find(findContainerBySelector);
        if (!many) containerFound = containerFound.first();
        if (containerFound.length <= 0 || containerFound == undefined) {
            this.#logRequiredStructure();
            throw new StructureError(`${this.#_name}: selector ${findContainerBySelector} not found in ${this.#_globalContainer}`);
        }
        return containerFound;
    }

}